import { createAxiosClient } from '@nx/axios-client'
import { BonhamsCookies } from '@nx/global-types'

import { User } from '../types'

export async function getUser(
  baseURL: string,
  cookies: BonhamsCookies
): Promise<User | null> {
  const authenticatedAxiosClient = createAxiosClient({
    accessTokenSSR: cookies?.bonhams_access || '',
    refreshTokenSSR: cookies?.bonhams_token || '',
    baseURL,
  })

  return authenticatedAxiosClient
    .post<User>(
      '/api/v2/client/token/',
      {
        token: cookies.bonhams_access,
      },
      {
        transformRequest: [
          function (data, headers) {
            delete headers.Authorization

            return JSON.stringify(data)
          },
        ],
        headers: {
          'Content-Type': 'application/json',
        },
        baseURL,
      }
    )
    .then((response) => response.data || null)
}
